<template>
  <div class="interest-info">
    <img
        src="../../../assets/images/Amblem2@2x.webp"
        width="32"
        height="38"
        alt="Glocal logo"
    />
    <div class="heading-box">
      <h1 class="heading-box__heading">
        Sign up to Glocal and experience the News SuperIntelligence environment
      </h1>
      <div class="heading-box__progress">
        <span class="progress-line progress-line--1"/>
        <span class="progress-line progress-line--2"/>
        <span class="progress-line progress-line--3"/>
      </div>
    </div>
    <div class="interest-info__list">
      <interest-list @update-list="updateList"/>
    </div>
    <p v-if="error" class="error">{{ error }}</p>
    <div class="buttons">
      <a-button
          type="primary"
          class="btn back-btn"
          @click="$emit('next', 'security')"
      >
        Go Back
      </a-button>
      <router-link
          type="primary"
          class="btn continue-btn-primary btn-primary"
          @click="submit"
          :loading="loading"
          to="/verifiaction-status"
      >
        Get Started
      </router-link>
    </div>
  </div>
</template>

<script>
import InterestList from "../../BaseComponents/InterestList.vue";
import ApiService from "../../../services/api.service.js";

export default {
  data() {
    return {
      interests: [],
      emailSent: false,
      error: "",
      loading: false,
    };
  },
  components: {
    InterestList,
  },
  methods: {
    updateList(list) {
      this.interests = list;
    },
    async submit() {
      this.loading = true;
      this.error = "";
      const requestObj = {
        email: this.$store.state.auth.email,
        password: this.$store.state.auth.password,
        confirm_password: this.$store.state.auth.password,
        profile: {
          full_name: this.$store.state.auth.fullName,
          phone_number: "+923101234567",
          interests_list: Object.values(this.interests),
        },
      };
      ApiService.removeHeader();
      await ApiService.post("user/create/", requestObj)
          .then((response) => {
            if (response.status === 201) {
              ApiService.post("user/send-verification-email/", {
                email: requestObj.email,
              }).then((res) => {
                if (res.status === 200) {
                  this.emailSent = true
                }
              });
            }
          })
          .catch((err) => {
            Object.keys(err.response.data).forEach((key, i, arr) => {
              err.response.data[key].forEach((val) => {
                this.error += val + "\n";
              });
            });
          });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.interest-info {
  padding: 4.8rem 6rem;
  font-family: $font-secondary;
  height: 100vh;

  .success-msg {
    margin-bottom: 2rem;
  }

  .heading-box {
    padding-top: 2.1rem;
    display: flex;
    justify-content: space-between;

    &__heading {
      font-size: 2rem;
      font-family: $font-secondary-bold;
      width: 33rem;
      margin: 0;

      @include respond(laptop-small) {
        width: 60%;
      }
    }

    .progress-line {
      width: 3.7rem;
      height: 0.4rem;
      display: inline-block;

      &--1,
      &--2,
      &--3 {
        background-color: $color-primary;
      }
    }

    .progress-line:not(:last-child) {
      margin-right: 0.7rem;
    }
  }

  &__list {
    padding: 3.5rem 0 0.7rem;

    .ant-checkbox-wrapper {
      padding: 2rem;
      background-color: #f2f5ff;
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0 !important;

      span {
        font-size: 1.8rem;
        font-family: $font-secondary-bold;
        font-weight: 900;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $color-primary;
      }

      .ant-checkbox {
        top: -3px;
      }
    }
  }

  .buttons {
    display: flex;
    margin-top: 1rem;

    .btn {
      margin: 0 0.8rem 3rem 0;
      font-size: 1.6rem;
      font-family: $font-secondary;
      padding: 0;
      line-height: 0;
      height: 4.8rem;
      width: 19rem;
    }

    .back-btn {
      border-radius: 0;
      border-color: transparent;
      background-color: #191919;
    }
  }
}
</style>
