<template>
  <div class="personal-info">
    <a href="/">
      <img
          src="../../../assets/images/Amblem2@2x.webp"
          width="32"
          height="38"
          alt="Glocal logo"
      />
    </a>
    <div class="heading-box">
      <h1 class="heading-box__heading">
        Sign up to Glocal and experience the News SuperIntelligence environment
      </h1>
      <div class="heading-box__progress">
        <span class="progress-line progress-line--1"/>
        <span class="progress-line progress-line--2"/>
        <span class="progress-line progress-line--3"/>
      </div>
    </div>
    <h6 class="personal-info__heading">Personal Information</h6>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="full-name">Full Name / Pseudonym</label>
        <input
            data-cy="updateFullName"
            data
            type="text"
            id="full-name"
            placeholder="Enter Name"
            required
            v-model="fullName"
        />
      </div>
      <div class="form-group">
        <label for="email">Email Address</label>
        <input
            data-cy="updateEmailAddress"
            type="email"
            id="email"
            placeholder="Enter Email"
            required
            v-model="email"
        />
      </div>
      <p v-if="!!error" class="error">{{ error }}</p>
      <a-checkbox v-model:checked="checked" class="privacy-policy-checkbox">I agree to the
        <a href="#" class="privacy-policy-checkbox--privacy" @click="setStatement('privacy')">privacy notice</a>
        and
        <a href="#" class="privacy-policy-checkbox--policy" @click="setStatement('policy')">policy statement</a>
      </a-checkbox>
      <a-button
          :class="{ 'disable-save-btn': !checked }"
          htmlType="submit"
          :loading="loading"
          class="continue-btn-primary btn-primary"
      >
        Save &amp; Continue
      </a-button>
    </form>
    <privacy-and-policy-modal
        :type="type"
        v-model:visible="showPrivacyPolicyModal"
        :footer="null"
        wrapClassName="privacy-policy-modal"
        width="88rem"
        centered
    />
  </div>
</template>

<script>
import PrivacyAndPolicyModal from './PrivacyAndPolicyModal.vue';

export default {
  data() {
    return {
      fullName: "",
      email: "",
      loading: false,
      imageUrl: "",
      file: null,
      error: "",
      checked: false,
      type: "",
      showPrivacyPolicyModal: false
    };
  },
  components: {
    PrivacyAndPolicyModal
  },
  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    beforeUpload(file) {
      this.file = file;
      return false;
    },
    handleUpload() {
      this.loading = true;
      this.getBase64(this.file, (imageUrl) => {
        this.imageUrl = imageUrl;
        this.loading = false;
      });
    },
    async submitForm() {
      try {
        this.error = "";
        this.loading = true;
        const payload = {
          fullName: this.fullName,
          email: this.email,
          profilePic: this.file,
        };
        const response = await this.$store.dispatch(
            "auth/validateEmail",
            payload
        );
        if (response.status === 200) {
          this.$emit("next", "security");
        }
      } catch (error) {
        this.error = error.response.data.non_field_errors[0];
      }
      this.loading = false;
    },
    setStatement(val) {
      this.type = val;
      this.showPrivacyPolicyModal = true;
    }
  },
};
</script>

<style lang="scss">
@include reset-css;

.personal-info {
  padding: 4.8rem 6rem;

  .heading-box {
    padding-top: 2.1rem;
    display: flex;
    justify-content: space-between;

    &__heading {
      font-size: 2rem;
      font-family: $font-secondary-bold;
      width: 33rem;
      margin: 0;

      @include respond(laptop-small) {
        width: 60%;
      }
    }

    &__progress {
      width: 13rem;

      .progress-line {
        width: 3.7rem;
        height: 0.4rem;
        display: inline-block;

        &--1 {
          background-color: $color-primary;
        }

        &--2,
        &--3 {
          background-color: #c8c8c8;
        }
      }

      .progress-line:not(:last-child) {
        margin-right: 0.7rem;
      }
    }
  }

  .personal-info__heading {
    font-size: 1.6rem;
    font-family: $font-secondary-bold;
    padding: 1.5rem 0 1.1rem 0;
  }

  .profile-picture {
    font-size: 1.6rem;
    margin: 0;
    padding-bottom: 2rem;
  }

  .upload-image {
    display: flex;
    align-items: center;
    margin-bottom: 1.1rem;

    &__file-input {
      display: none;
    }

    &__browse {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #file-input__label {
      width: 7.3rem;
      height: 7.3rem;
      border: 1px solid #707070;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(phone-large) {
        width: 10rem;
        height: 10rem;
      }
    }
  }

  .continue-btn-primary {
    margin-top: 3.8rem;
    font-size: 1.6rem;
    font-family: $font-secondary;
    padding: 1.8rem 5rem;
    display: flex;
    height: 4.8rem;
    width: 19rem;
    border: none;
  }

  .error {
    text-align: left;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    label {
      font-size: 1.6rem;
      font-family: $font-secondary;
      margin: 1.4rem 0;
    }

    input {
      @include respond(laptop-small) {
        width: 100%;
      }

      width: 40.07rem;
      height: 3.9rem;
      border: 1px solid #70707033;
      background-color: #f6f8ff;
      padding: 0 2rem;
      outline: none;

      &::-webkit-input-placeholder {
        font-family: $font-secondary;
        font-size: 1.6rem;
      }

      &::-moz-placeholder {
        font-family: $font-secondary;
        font-size: 1.6rem;
      }
    }
  }

  .privacy-policy-checkbox {
    margin-top: 1rem;

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $color-primary;
        background-color: $color-primary;
      }
    }

    span {
      font-size: 1.6rem;
      font-family: $font-secondary;

      a {
        color: $color-faded-blue;
      }
    }
  }
}

.upload-image {
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .avatar-uploader {
    .ant-upload.ant-upload-select-picture-card {
      width: 9rem !important;
      height: 9rem !important;
      border: 1px solid #707070 !important;
      border-radius: 0px !important;
      background-color: transparent !important;
      margin-right: 2rem !important;
    }
  }
}

.upload-btn {
  border: none;
  height: fit-content;

  .ant-upload {
    border: none !important;
    height: fit-content !important;
    width: fit-content !important;
    padding: 0 !important;
  }
}

.ant-upload {
  .uploaded-img {
    width: 100%;
    height: 100%;
  }
}

.upload-btn {

  .ant-upload-select-picture-card {
    border: none !important;
  }

  .upload-image__btn-primary {
    font-size: 1.6rem;
    font-family: $font-secondary;
    padding: 0 4.4rem;
    border: none !important;
    height: 4.3rem;
  }
}

.disable-save-btn {
  pointer-events: none;
  background-color: gray;
  opacity: 0.5;
}
</style>
